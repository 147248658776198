import React, { useState, useEffect } from "react";
import { graphql, StaticQuery } from "gatsby";
import { Index } from "elasticlunr";
import { Box, Container, Stack, Typography, Button, Link } from "@mui/material";


const MAX_RESULTS_PER_PAGE = 10;

const getResultsForPage = (results, page) => {
  const start = page * MAX_RESULTS_PER_PAGE;
  const end = start + MAX_RESULTS_PER_PAGE;
  return results.slice(start, end);
}

const SearchResults = ({ results, page }) => {

  const articleStyle = {
    borderColor: "#eaeaeb",
    borderBottomStyle: "solid",
    borderBottomWidth: "0.07143em",
    paddingBottom: "2.60714em",
    marginBottom: "2.67857em",
  }

  const linkSx = {
    color: "black",
    transition: "0.3s",
    '&:hover': {
      color: "rgb(8, 150, 254)",
      textDecoration: "none",
    }
  }

  const excerptSx = {
    marginTop: "10px",
  }

  if (results.length===0) {
    return (
      <Typography>
        Sorry, but nothing matched your search terms. Please try again with some different keywords.
      </Typography>
    )
  }
  else {
    return getResultsForPage(results, page).map((res) => {
      const { id, title, slug, excerpt } = res;
      return (
        <article key={id} style={ articleStyle }>
          <Typography variant="h1">
            <Link href={slug} underline="none" sx={ linkSx }>
              <span dangerouslySetInnerHTML={{ __html: title }}></span>
            </Link>
          </Typography>
          <Typography sx={ excerptSx }>
            { excerpt }
          </Typography>
        </article>
      )
    })
  }
}

const pageBtnSx = {
  background: "#eaeaea",
  color: "#5a5d60",
  fontWeight: 400,
  padding: "0.7142em 1.1428em",
  borderRadius: "3px",
  transition: ".3s",
  '&:hover': {
    background: "#0896fe",
    color: "#fff",
  },
  textTransform: 'none',
}

const HiddenTextBox = ({ text }) => (
  <Box sx={ { ...pageBtnSx, background: "transparent", color: "transparent", pointerEvents: "none" } }>
    { text }
  </Box>
)

const SearchResultsContainer = ({ searchIndex, query }) => {

  const index = Index.load(searchIndex);
  const [results, setResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const searchResults = (searchQuery) => {
    const res = index.search(searchQuery, { expand: true }).map(({ ref }) => {
      return index.documentStore.getDoc(ref)
    });
    setResults(res);
  }

  useEffect(() => {
    searchResults(query);
  }, [query]);

  const numPages = Math.ceil(results.length / MAX_RESULTS_PER_PAGE);

  const handleClickPrevNext = (e) => {
    const val = e.target.innerHTML;
    (val==="Previous") ? setCurrentPage(currentPage-1) : setCurrentPage(currentPage+1);
  }

  const handleClickPage = (e) => {
    const val = +e.target.innerHTML-1;
    (val!==currentPage) && setCurrentPage(val);
  }

  const PageButtons = () => {
    const buttons = [];
    for (let i=0; i<numPages; i++) {
      let sx = { ...pageBtnSx, };
      i===currentPage && (sx = { ...sx, background: "#0896fe", color: "#fff", pointerEvents: "none" });
      buttons.push(
        <Button onClick={handleClickPage} sx={ sx }>{i+1}</Button>
      )
    }
    return buttons;
  }

  return (
    <Container fixed>
      {
        results &&
        <>
          <SearchResults results={results} page={currentPage}/>
          {
            results.length > MAX_RESULTS_PER_PAGE &&
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={ {marginTop: "3.26429em"} }>
              {
                currentPage>0 ?
                <Button onClick={handleClickPrevNext} sx={ pageBtnSx }>Previous</Button> :
                <HiddenTextBox text="Previous"/>
              }
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={0.5}>
                <PageButtons />
              </Stack>
              {
                !(currentPage+1===numPages) ?
                <Button onClick={handleClickPrevNext} sx={ pageBtnSx }>Next</Button> :
                <HiddenTextBox text="Next"/>
              }
            </Stack>
          }
        </>
      }
    </Container>
  );
};

const Search = ({ query }) => {

  return (
    <StaticQuery
      query={graphql`
        query SearchIndexQuery {
          siteSearchIndex {
            index
          }
        }
      `}
      render={(data) => (
        <SearchResultsContainer
          searchIndex={data.siteSearchIndex.index}
          query={query}
        />
      )}
    />
  );
}

export default Search;
