import * as React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import EntryHeader from "../components/entry-header";
import Footer from "../components/footer";
import Search from "../components/search";
import { useIsClient } from "../components/hooks/use-is-client";
import ScrollToTop from "../components/scroll-to-top.js";
import { useScrollPaddingTop } from "../hooks/use-scroll-padding-top";
import { Box, Stack, Typography, Button, } from "@mui/material";
import { styled } from '@mui/material/styles';
import LandingPageBgImage from "../images/CC-notation.png";


const SearchPage = ({ query }) => {

  useScrollPaddingTop();

  return (
    <Layout>
      <Seo title={query ? "Search" : "Home"}/>
      {
        query &&
        <>
          <EntryHeader title={`Search Results for: ${query}`}/>
          <Search query={query}/>
          <ScrollToTop />
        </>
      }
    </Layout>
  )
}

const BgImage = styled("div")({
  //content: `''`,
  backgroundImage: `url(${LandingPageBgImage})`,
  backgroundPosition: "center center",
  backgroundSize: "cover",
  opacity: 0.09,
  position: "absolute",
  top: "0px",
  right: "0px",
  bottom: "0px",
  left: "0px",
  zIndex: -10000,
});

const LandingPage = () => {

  const titleStyle = {
    padding: "13% 20px 13% 20px",
    //maxWidth: "1280px",
    textAlign: "center",
  }

  const btnStyle = {
    border: "2px solid #000000",
    borderRadius: 0,
    fontSize: "1.45em", //"1.15em",
    padding: "1em 2em;", //"1em 1.65em",
    color: "#000000 !important",
    background: "white",
  }

  return (
    <div>
      <Stack justifyContent="center" alignItems="center" sx={ titleStyle }>
        <Typography variant="h2" sx={ { paddingBottom: 1 } }>John Cage</Typography>
        <Typography variant="h4" sx={ { paddingBottom: 1 } }>and the</Typography>
        <Typography variant="h2" sx={ { paddingBottom: 2 } }>
          <em>Concert for Piano and Orchestra</em>
        </Typography>
        <Typography variant="h5" sx={ { paddingBottom: 1, color: "#6b6d6f! important", } }>
          An AHRC-funded project based at
        </Typography>
        <Typography variant="h5" sx={ { paddingBottom: 4, color: "#6b6d6f! important", } }>
          the Universities of Huddersfield and Leeds
        </Typography>
        <Button id="landing-page-button" variant="outlined" href="/project">ENTER</Button>
        <BgImage />
      </Stack>
      <Footer />
    </div>
  )
}

const IndexPage = ({ location }) => {

  // We use this hook to avoid the rehydration issue.
  // See https://blog.logrocket.com/fixing-gatsbys-rehydration-issue/.
  const { isClient, key } = useIsClient();

  const { search } = location;
  const query = new URLSearchParams(search).get("s");

  if ( !isClient ) return null;
  return (
    <div key={key}>
      {
        query ? <SearchPage query={query}/> : <LandingPage />
      }
    </div>
  )
}

export default IndexPage;
