import { useState, useEffect } from "react";


// Adapted fro https://blog.logrocket.com/fixing-gatsbys-rehydration-issue/

export const useIsClient = () => {
  const [isClient, setClient] = useState(false);
  const key = isClient ? "client" : "server";

  useEffect(() => {
    setClient(true);
  }, []);

  return { isClient, key };
};
